import { useState } from 'react';
import React from 'react'

function addToCart(){}
function updateCartTotal(){}
function removeCartItem(){}
function quantityChanged(){}

const Products = () => {
  let counter = 0;
  let [counterState, setCounterState] = useState(0)
  const incrementCounter = () => { 
    setCounterState(++counterState)  
  }
  return (
    <>
    <h1 className='insod'>{counter}</h1>
    <h1 className='insod'>{counterState}</h1>
    <button onClick={incrementCounter}>incrementCounter</button>
    </>
  )
}

export default Products;