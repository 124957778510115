import logo from './logo.svg';
import './App.css';
import { supabase } from './supabase_client';
import { useState, useEffect } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import BasicNav from './basic_nav';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavBarPlus from './navbar';
import NavX1 from './nav_x1';
import Listing from './listing';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

function Fake(){
    const router = createBrowserRouter([
        {},
        {}
      ])
      const [user,setuser] = useState([])
      console.log(user)
      useEffect(() => {
        fu()
      }, [])
      async function fu(){
        const {data} = await supabase.from("ONSUNDAYSTORE_LOCATIONS").select("*")
        setuser(data)
      }    

    return(
        <>
    <div>
        <div className='xi'>

            {user.map((u) =>
            <div>
                <div className='insod'>
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{u.store_name}</h5>
                            <p class="card-text">{u.store_address}, {u.store_city}</p>
                            <a href="#" class="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    </div>
        
        </>
    )
}

export default Fake;
