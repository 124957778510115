import './App.css';
import { Link } from 'react-router-dom'
import Products from './Products';
function NavX1(){
    return(
    <>
        <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                    <a class="navbar-brand" href="#">onsunday.store</a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    {/* collapse navbar-collapse justify-content-end */}
                <div class="" id="navbarNav">
                    <ul class="navbar-nav justify-content-center">
                        <li class="nav-item active">
                            <Link className='nav-link' to='/stores'>Stores</Link>
                        </li>
                        <li class="nav-item">
                            <Link className='nav-link' to='/products'>Products</Link>
                        </li>
                        <li class="nav-item">
                            <Link className='nav-link' to='/discounts'>Discounts</Link>
                        </li>
                        <li class="nav-item">
                            <Link className='nav-link' to='/support'>Support</Link>
                        </li>
                        <li class="nav-item">
                            <Link className='nav-link' to='/login'>Account</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>
    )
}

export default NavX1;