import { supabase } from './supabase_client';
import './App.css';
import { useState, useEffect } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import BasicNav from './basic_nav';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavBarPlus from './navbar';
import NavX1 from './nav_x1';
import Listing from './listing';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Fake from './fake';
import PaymentCancel from './PaymentCancel';
import PaymentSuccess from './PaymentSuccess';
import Discounts from './Discounts';
import Products from './Products';
import Login from './Login';
import Support from './Support';
import Profilesx from './Profilesx';
/*
<div class="card" style="width: 18rem;">
  <img src="..." class="card-img-top" alt="...">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>
*/

function App() {
  const [user,setuser] = useState([])
  console.log(user)
  useEffect(() => {
    fu()
  }, [])
  async function fu(){
    const {data} = await supabase.from("ONSUNDAYSTORE_LOCATIONS").select("*")
    setuser(data)
  }    

  return (
    <>
    <NavX1></NavX1>
      <Routes>
        <Route exact path="/stores" element={<Listing/>}></Route>
        <Route exact path="/products" element={<Products/>}></Route>
        <Route exact path="/discounts" element={<Discounts/>}></Route>
        <Route exact path="/support" element={<Support/>}></Route>
        <Route exact path="/login" element={<Login/>}></Route>
        <Route exact path="/payment_success" element={<PaymentSuccess/>}></Route>
        <Route exact path="/payment_cancel" element={<PaymentCancel/>}></Route>
        <Route exact path="/profile" element={<Profilesx/>}></Route>
      </Routes>
    </>
  );
}

export default App;

/*
SELECT * FROM "ONSUNDAYSTORE_LOCATIONS"

INSERT INTO "ONSUNDAYSTORE_LOCATIONS" (storeid, store_name, store_address, store_city)
VALUES (27, "", "MM ALAM ROAD", "LAHORE");

UPDATE "ONSUNDAYSTORE_LOCATIONS"
SET store_city = "LAHORE"
WHERE storeid=27;

update "ONSUNDAYSTORE_LOCATIONS"
set
  store_city = 'LAHORE'
where
  storeid = 27;


do $$
begin
   for cnt in 28..60 loop
    update "ONSUNDAYSTORE_LOCATIONS"
    set
      store_city = 'LAHORE'
    where
      storeid = cnt;
   end loop;
end; $$

do $$
begin
   for cnt in 28..60 loop
    update "ONSUNDAYSTORE_LOCATIONS"
    set
      store_address = 'LAHORE'
    where
      storeid = cnt;
   end loop;
end; $$
*/