import { createClient } from "@supabase/supabase-js";

export const supabase = createClient("https://dkegajolxxlajvibhvou.supabase.co","eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRrZWdham9seHhsYWp2aWJodm91Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM4ODAwODksImV4cCI6MjAyOTQ1NjA4OX0.w1wNG1VCqyf8f92_zoj1BV3CBBBAY_8-FBu19G-Ytzo")

/*
install the supabase javascript client library
npm install @supabase/supabase-js
supabase client is being used.
declare a constant using const keyword.
createClient function is declared.
createClient function takes two parameters.
the first parameter is supabaseUrl.
the first parameter is required.
the second parameter is supabaseKey.
the second parameter is required.
*/