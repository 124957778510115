import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './App.css';
import Pagination from './Pagination';
import { supabase } from './supabase_client';
import { useState, useEffect } from 'react';
import { createBrowserRouter } from 'react-router-dom';

/*

const { data, error } = await supabase
  .from('cities')
  .select('name, country_id')
  .eq('name', 'The Shire')    // Correct

const { data, error } = await supabase
  .from('cities')
  .eq('name', 'The Shire')    // Incorrect
  .select('name, country_id')
*/


function Listing(){
    const router = createBrowserRouter([
        {},
        {}
      ])
      const [user,setuser] = useState([])
      useEffect(() => {
        fu()
      }, [])
      async function fu(){
        const {data} = await supabase.from("ONSUNDAYSTORE_LOCATIONS").select("*").order('storeid', { ascending: true })
        setuser(data);
      }    
      
      /* 
        the pagination system includes six variables
        totalposts
        currentposts ( which is a part of the total posts )
        we can get currentposts using totalpost.slice()
        
        currentpage ( which at the start of the application is going to be 1 )
        ( this number will be used to change posts )
        postsperpage ( it could be 4, 5, 10 )

        lastpostindex = currentpage * postsperpage
        firstpostindex = lastpostindex - firstpostindex

        at the end the application will render the data from currentPosts

      */
      const [currentPage, setCurrentPage] = useState(1);
      const [postPerPage, setPostPerPage] = useState(10);
      const lastPostIndex = currentPage * postPerPage;
      const firstPostIndex = lastPostIndex - postPerPage;
      const currentPosts = user.slice(firstPostIndex,lastPostIndex);  
      return(
        <>
        <div className='insod mt-13'>
            {currentPosts.map((u) =>
                <div>
                    <div class="card">
                        <div class="card-body">
                            <h5 class="text-sm font-semibold leading-6 text-gray-900">{u.storeid+1}-{u.store_name}</h5>
                            <p class="card-text">{u.store_address}, {u.store_city}</p>
                            
                        </div>
                    </div>
                </div>
            )}
        </div>
        <Pagination totalPosts={user.length} PostsPerPage={postPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
        </>
    )
}

export default Listing;

// listing without pagination
// drawbacks of listing without pagination
// for 10000 listing, the page loaded will be big.
// we need a solution which limits the number of listings.
// listing with pagination
// server side pagination
//<a href="#" class="btn btn-primary">Call</a>

/*
pagination logic
we have 1000 products
we have an array of 1000 products
to show products on first page
we work with index
we are on page 1
products per page is 10
first product index is 1
last product index is 10
const lastPostIndex = currentPage * postPerPage;
we are on page 2
currentpage is 2
postperpage is 10
lastpostindex will be 20
products per page is 10
first product index is 11
last product index is 20
*/